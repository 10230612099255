import React, {Fragment} from 'react';
import Layout from '../components/layout/layout'
import Fold from '../components/Fold/Fold';
import {TextPhotoSection} from '../components/TextPhotoSection/TextPhotoSection';
import {IntroHolder} from '../components/IntroHolder/IntroHolder';
import {SectionFullscreenImage} from '../components/SectionFullscreenImage/SectionFullscreenImage';
import {JobsSection} from '../components/JobsSection/JobsSection'; // Make sure to import the modified JobsSection component
import {NoteSection} from '../components/NoteSection/NoteSection';
import {IntroSection} from '../components/IntroSection/IntroSection';
import FadeDown from '../components/FadeDown/FadeDown';
import CtaSection from '../components/CtaSection/CtaSection';
import {Container} from '../components/Container/Container';
import {graphql, useStaticQuery} from "gatsby";

const IMAGE_QUERY = graphql`
    {
        whoWeAre2: file(relativePath: { eq: "careers/who-we-are2.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        introImage: file(relativePath: { eq: "careers/intro-image.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1340
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        allStrapiJobPost {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

const Careers = () => {
    const tempImage = useStaticQuery(IMAGE_QUERY);

    const hasJobPosts = tempImage.allStrapiJobPost.edges.length > 0;
    const marginBottomNone = !hasJobPosts; // Set marginBottomNone to true if there are no job posts

    return (
        <Layout
            pageTitle="Careers"
            description="Join the team of passionate developers, engineers, and designers delivering ambitious products."
        >
            <FadeDown fold>
                <Fold
                    title={<Fragment><span>Career</span> at Kickstage</Fragment>}
                    description="We need bold adventurers to explore the latest technologies just as much as professionals to build reliable solutions for the demanding payloads of our clients."
                    btnText="See job listing"
                    btnLink="#job-listing"
                />
            </FadeDown>
            <FadeDown>
                <IntroSection
                    image={tempImage.introImage}
                    title="careers"
                />
            </FadeDown>

            {/* Update IntroHolder based on the presence of job posts */}
            <IntroHolder
                id="job-listing"
                marginBottomNone={marginBottomNone}
                title={
                    <div>From Zagreb or remote, these are our <span>open positions</span></div>
                }
                level="h2"
                centered
            />
            <JobsSection/>
            <NoteSection
                text={
                    <Fragment>
                        {hasJobPosts
                            ? "The job you are looking for is not on the open positions list? No problem we’re always on the lookout for new talent."
                            : "We don't have any open positions right now, but we'd love to hear from you if you're passionate about what we do."
                        }

                    </Fragment>}
                link="/open-application/"
                btnText="Send us an Open Application"
            >
            </NoteSection>
            <IntroHolder
                title={<Fragment> Get to <span>know us</span></Fragment>}
                level="h2"
                centered
            />
            <TextPhotoSection
                title="Who are we ?"
                description="Kickstage is a young IT consulting company working with renown clients from around the world. We
        accelerate digitalization in companies of all industries. We support our clients with their
        strategy, project management and the development of the software that drives their efforts."
                reverse
                smallDescription
                singleImage
                image={tempImage.whoWeAre2}
                radialmagesDecoration
                radialmagesDecorationColor="mandy"
                radialmagesDecorationPosition="bottom_left"
                smallPaddingTop
                imageRight
            />
            <Container>
                <SectionFullscreenImage
                    title="What we do?"
                    description="At Kickstage we believe in quality and focus. Therefore you will never work on
          more than one project in parallel and can focus 100% on the task. We expect great results but will make sure you get the time needed to deliver them."
                    heading="h3"
                />
            </Container>
            <CtaSection
                title="Let's Chat"
                label="What Can We Do For You?"
                link="/contact/"
            />
        </Layout>
    );
};

export default Careers;
