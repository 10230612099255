import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import "./jobs-section.scss";
import {Heading} from "../Heading/Heading";
import "../CardJob/card-job.scss";
import {ArrowRight} from "react-feather";
import FadeDown from "../FadeDown/FadeDown";

export const JobsSection = () => {
  const data = useStaticQuery(graphql`
    query SiteMetaData {
      allStrapiJobPost(sort: { date: ASC }) {
        edges {
          node {
            id
            slug
            position
            type
            applicationDueDate(formatString: "MMMM DD, YYYY")
            image {
              id
            }
            date(formatString: "MMMM DD, YYYY")
            content {
              data {
                content
              }
            }
            description
          }
        }
      }
    }
  `);

  // Check if there are any job posts
  const hasJobPosts = data.allStrapiJobPost.edges.length > 0;

  return (
    <>
      {hasJobPosts && (
        <section className="jobs-section">
          <div className="container ">
            <div className="gr-12 no-gutter@md">
              <div className="jobs-section__wrap">
                {data.allStrapiJobPost.edges.map(({ node }) => (
                  <FadeDown key={node.slug}>
                    <div key={node.slug}>
                      <Link
                        to={node.slug}
                        aria-label={`${node.title} job listing`}
                      >
                        <div className="card-job">
                          <div className="card-job__wrap">
                            <div className="card-job__details">
                              <Heading level="h3" job>
                                {node.position}
                              </Heading>
                              <div className="items">
                                <div className="item">
                                  <p className="type">{node.type}</p>
                                </div>
                              </div>
                            </div>
                            <div className="card-job__cta">
                              <button
                                className="btn btn--small_icon"
                                aria-label={`${node.title} job details`}
                              >
                                <span className="btn__icon">
                                  <ArrowRight size="24" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </FadeDown>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
