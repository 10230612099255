import * as React from 'react';
import './fullwidth-image-section.scss';
import { Heading } from '../../components/Heading/Heading';
import { StaticImage } from "gatsby-plugin-image";



export const SectionFullscreenImage = ({ title, description, heading }) => {
  return (
    <section className="section-fullscreen-image">
      <div className="container container--fullwidth">
        <div className="gr-12 no-gutter">
          <div className="section-fullscreen-image__holder">
            <div className="section-fullscreen-image__content">
              <Heading
                level={heading}
                animated
                small
                decorationDown
              >
                {title}
              </Heading>
              <p className="section-fullscreen-image__description">{description}</p>
            </div>
          </div>
        </div>
        <div className="gr-12">
          <div className="section-fullscreen-image__images-holder">
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-1.jpg" className='rspimg image' alt="Kickstage team image" />
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-2.jpg" className='rspimg image' alt="Kickstage team image" />
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-3.jpg" className='rspimg image' alt="Kickstage team image" />
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-4.jpg" className='rspimg image' alt="Kickstage team image" />
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-5.jpg" className='rspimg image' alt="Kickstage team image" />
            <StaticImage src="../../images/careers/careers-img/careers-img-1920-6.jpg" className='rspimg image' alt="Kickstage team image" />
          </div>
        </div>
      </div>
    </section>
  )
}
